import React from "react"
import { Helmet } from "react-helmet"

const ArticleData = ({
  title,
  author,
  date,
  articleDataImage,
  description,
  pathname,
}) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.iliadgrow.com${pathname}",
            "name": "${title}"
          },
          "headline": "${title}",
          "image": [
            "https://www.iliadgrow.com${articleDataImage}"
          ],
          "datePublished": "${date}",
          "author": {
            "@type": "Person",
            "name": "${author}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Iliad Epic Grow"
          },
          "description": "${description}"
        }
      `}
    </script>
  </Helmet>
)

export default ArticleData
